import { RedocStandalone } from "redoc";
import { useAuth0 } from "@auth0/auth0-react";
import { Dimmer, Loader, Container } from 'semantic-ui-react';

function App() {
    const { loginWithRedirect, isAuthenticated, isLoading, error } = useAuth0();
    
    if (isLoading) {
        return (
            <Dimmer active id="innerDimmer">
                <Loader inverted>Loading</Loader>
            </Dimmer>
    )}

    if (error) {
        window.location.replace(`${process.env.REACT_APP_ERROR_URL}?client_id=${process.env.REACT_APP_CLIENTID}&error=${error.name}&error_description=${error.message}`);
        return null
    }
    
    if (isAuthenticated) {
        return (
                <Container fluid className="container">
                    <div className="App">
                        <RedocStandalone
                            specUrl="https://docs.demo.okta.com/spec/openapi-310-spec.yaml"
                            options={{
                                nativeScrollbars: false,
                                theme: {
                                    lazyRendering: true,
                                    spacing: {
                                        unit: 5, // main spacing unit used in autocomputed theme values later
                                        sectionHorizontal: 40, // Horizontal section padding. COMPUTED: spacing.unit * 8
                                        sectionVertical: 40, // Horizontal section padding. COMPUTED: spacing.unit * 8
                                    },
                                    breakpoints: { // breakpoints for switching three/two and mobile view layouts
                                        small: '50rem',
                                        medium: '85rem',
                                        large: '105rem',
                                    },
                                    colors: {
                                        tonalOffset: 0.3, // default tonal offset used in computations
                                        primary: { 
                                            main: "blue" 
                                        }
                                    },
                                    typography: {
                                        fontSize: '14px',
                                        lineHeight: '1.5em',
                                        fontWeightRegular: '400',
                                        fontWeightBold: '600',
                                        fontWeightLight: '300',
                                        fontFamily: 'aeonik, sans-serif',
                                        smoothing: 'antialiased',
                                        optimizeSpeed: true,
                                        headings: {
                                            fontFamily: 'Montserrat, sans-serif',
                                            fontWeight: '400',
                                            lineHeight: '1.6em'
                                        },
                                        code: { // inline code styling
                                            fontSize: '13px',
                                            fontFamily: 'Courier, monospace',
                                            //lineHeight: ,// COMPUTED: typography.lineHeight
                                            //fontWeight: ,// COMPUTED: typography.fontWeightRegular
                                            color: '#e53935',
                                            backgroundColor: '#004E8F',
                                            wrap: false // whether to break word for inline blocks (otherwise they can overflow)
                                        },
                                        links: {
                                            //color: ,// COMPUTED: colors.primary.main
                                            //visited: ,// COMPUTED: typography.links.color
                                            //hover: ,// COMPUTED: lighten(0.2 typography.links.color)
                                            textDecoration: 'auto',
                                            hoverTextDecoration: 'auto'
                                        }
                                    },
                                    sidebar: {
                                        width: '260px',
                                        backgroundColor: '#fafafa',
                                        textColor: '#333333',
                                        //activeTextColor: ,// COMPUTED: theme.sidebar.textColor (if set by user) or theme.colors.primary.main
                                        groupItems: {// Group headings
                                            //activeBackgroundColor: ,// COMPUTED: theme.sidebar.backgroundColor
                                            //activeTextColor: ,// COMPUTED: theme.sidebar.activeTextColor
                                            textTransform: 'uppercase'
                                        },
                                        level1Items: { // Level 1 items like tags or section 1st level items
                                            //activeBackgroundColor: ,// COMPUTED: theme.sidebar.backgroundColor
                                            //activeTextColor: ,// COMPUTED: theme.sidebar.activeTextColor
                                            textTransform: 'none'
                                        },
                                        arrow: {// # sidebar arrow
                                            size: '1.5em',
                                            //color: // COMPUTED: theme.sidebar.textColor
                                        }
                                    },
                                    logo: {
                                        //maxHeight: // COMPUTED: sidebar.width
                                        //maxWidth: // COMPUTED: sidebar.width
                                        gutter: '2px' // logo image padding
                                    },
                                    rightPanel: {
                                        backgroundColor: '#00297A',
                                        width: '40%',
                                        textColor: '#ffffff',
                                        servers: {
                                            overlay: {
                                                backgroundColor: '#fafafa',
                                                textColor: '#263238'
                                            },
                                            url: {
                                                backgroundColor: '#fff'
                                            }
                                        }
                                    },
                                    fab: {
                                        backgroundColor: '#263238',
                                        color: '#ffffff'
                                    }
                                }
                            }}
                        />
                    </div>
                </Container>
            )
     } else {
         return loginWithRedirect();
     };
}

export default App;